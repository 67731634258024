<template>
  <div class="markup-tables flex">
    <va-card class="flex mb-4">
      <va-card-content>
        <va-breadcrumbs>
          <va-breadcrumbs-item :label="$t('common.list')" target="/admin/user" to="/admin/user" />
          <va-breadcrumbs-item :label="$t('common.detail')" disabled />
        </va-breadcrumbs>
        <div class="table-wrapper mt-4" ref="content">
          <page-title :pageTitle="$t('user.read_user')" />
          <va-inner-loading :loading="loading" color="primary" class="va-loading" :size="60" v-if="loading" />
          <user-form v-if="!loading" :readOnly="true" :initialData="user" />
        </div>
      </va-card-content>
    </va-card>
  </div>
</template>

<script>
import { GET_USER } from '@mdb/core/src';
import UserForm from './UserForm.vue';
import PageTitle from '@/components/text/PageTitle';
import { useGetEntity } from '@/components/helpers/useGetEntity';
import { useQuery, useResult } from '@vue/apollo-composable';
import { useRoute } from 'vue-router';

export default {
  components: { UserForm, PageTitle },
  setup(props) {
    const route = useRoute();

    const { item, result, loading, error } = useGetEntity({ id: route.params.id }, GET_USER);

    return {
      error,
      user: item,
      id: route.params.id,
      loading
    };
  }
};
</script>

<style lang="scss">
.markup-tables {
  .table-wrapper {
    overflow: auto;
  }

  .va-table {
    width: 100%;
  }
}
</style>
